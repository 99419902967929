$color_1: #a7a37e;
$color_2: #e6e2af;
$color_3: #068ab3;
$color_4: #efecca;
$color_5: #002f2f;
$font_family_1: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
$background_color_1: #002f2f;
$background_color_2: #024957;
$background_color_3: #e6e2af;
$background_color_4: #efecca;
$border_color_1: #002f2f;

/*!
 * Start Bootstrap - Landing Page Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
body {
	width: 100%;
	height: 100%;
	background-color: $background_color_1;
	font-family: $font_family_1;
	font-weight: 700;
}
html {
	width: 100%;
	height: 100%;
	background-color: $background_color_1;
}
h1 {
	font-family: $font_family_1;
	font-weight: 700;
}
h2 {
	font-family: $font_family_1;
	font-weight: 700;
}
h3 {
	font-family: $font_family_1;
	font-weight: 700;
}
h4 {
	font-family: $font_family_1;
	font-weight: 700;
}
h5 {
	font-family: $font_family_1;
	font-weight: 700;
}
h6 {
	font-family: $font_family_1;
	font-weight: 700;
}
.lead {
	font-size: 18px;
	font-weight: 400;
}
.intro-header {
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 50px;
	text-align: center;
	color: $color_1;
	border-bottom: 1px solid #a7a37e;
	background: url(../img/intro-bg.jpg) no-repeat center center;
	background-size: cover;
}
.intro-message {
	position: relative;
	padding-top: 20%;
	padding-bottom: 20%;
	>h1 {
		margin: 0;
		text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
		font-size: 5em;
	}
	>h3 {
		text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
	}
}
.intro-divider {
	width: 400px;
	border-top: 1px solid #a7a37e;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}
.network-name {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 2px;
}
.content-section-a {
	padding: 50px 0;
	background-color: $background_color_1;
	border-bottom: 1px solid #a7a37e;
	color: $color_2;
	a {
		color: $color_3;
	}
}
.content-section-b {
	padding: 50px 0;
	background-color: $background_color_2;
	border-bottom: 1px solid #a7a37e;
	color: $color_2;
	a {
		color: $color_3;
	}
}
.section-heading {
	margin-bottom: 30px;
}
.section-heading-spacer {
	float: left;
	width: 200px;
	border-top: 3px solid #e6e2af;
}
.banner {
	padding: 100px 0;
	color: $color_2;
	background: url(../img/banner-bg.jpg) no-repeat center center;
	background-size: cover;
	h2 {
		margin: 0;
		text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
		font-size: 3em;
	}
	ul {
		margin-bottom: 0;
	}
}
.banner-social-buttons {
	float: right;
	margin-top: 0;
}
footer {
	padding: 50px 0;
	background-color: $background_color_3;
}
p.copyright {
	margin: 15px 0 0;
}
.navbar {
	background-color: $background_color_1;
	padding-right: inherit;
}
.navbar-brand {
	color: $color_2 !important;
	&:hover {
		color: $color_4 !important;
	}
}
.page-scroll {
	color: $color_2 !important;
	&:hover {
		color: $color_4 !important;
	}
}
.btn-default {
	color: $color_5 !important;
	background-color: $background_color_3;
	border-color: $border_color_1;
	&:hover {
		color: $color_5 !important;
		background-color: $background_color_4;
		border-color: $border_color_1;
	}
}
@media (max-width:767px) {
	.intro-message {
		padding-bottom: 15%;
		>h1 {
			font-size: 3em;
		}
	}
	ul.intro-social-buttons {
		>li {
			display: block;
			margin-bottom: 20px;
			padding: 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.intro-divider {
		width: 100%;
	}
	.banner {
		h2 {
			margin: 0;
			text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
			font-size: 3em;
		}
	}
	
}

